// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("turbolinks").start()
require("@rails/ujs").start()

require("./time_metrics")

const EasyMDE = require("easymde")
const hljs = require("highlight.js")
const marked = require("marked")
const ClipboardJS = require("clipboard")
const Tagify = require("@yaireo/tagify")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener('turbolinks:load', (event) => {
  if (document.getElementById("snippet_body")) {
    new EasyMDE({ element: document.getElementById("snippet_body"), spellChecker: false })
  } else if (document.getElementById("snippet_body_preview")) {
    document.getElementById('snippet_body_preview').innerHTML =
      marked(document.getElementById('snippet_body_preview').innerHTML.trim());
  }

  document.querySelectorAll('pre code.custom-hljs').forEach((block) => {
    hljs.highlightElement(block);
  });

  new ClipboardJS('.copy-btn')

  if (document.querySelector('input.tagify')) {
    var tagify_input = document.querySelector('input.tagify')
    var tagify_wrapper = new Tagify(tagify_input, {whitelist: window.all_tags,  dropdown : {classname: "color-blue", enabled: 1, maxItems: 5}})
  }

  if(document.querySelector('.sf-search')) {
    document.querySelector('.sf-search').addEventListener('keyup', event => {
      return fetch(`/search/q?q=${event.target.value}&tag=${window.current_tag}`, {})
        .then(response => {
          return response.text()
        }).then(text => {
          document.querySelector('.sf-search-results').innerHTML = text
        });
    });

    document.querySelector('.sf-search').addEventListener('keypress', function (e) {
      var key = e.which || e.keyCode
      if (e.key === 'Enter') {
        const href = document.querySelector('.sf-search-results .sf-tab-item a')
        href && href.click()
      }
    })
  }

  if (document.querySelector('#comment_body')) {
    document.querySelector('#comment_body').addEventListener('keydown', event => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        document.querySelector('#new_comment').submit()
      }
    })
  }

  // Don't ask
  if (document.querySelector('.sf-commcard-side-notifications')) {
    for (var side_notification of document.querySelectorAll('.sf-commcard-side-notifications')) {
      side_notification.addEventListener('click', event => {
        if(event.target.tagName !== 'A') {
          event.target.children[0].click()
        }
      })
    }
  }
})
