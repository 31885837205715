const jstz = require('jstz');

document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('#scope-input')) {
    const radioButtons = document.querySelectorAll('input[type="radio"][name="display"]');
    const targetDiv = document.getElementById('scope-input');

    // Add event listener to each radio button
    radioButtons.forEach(function (radioButton) {
      radioButton.addEventListener('change', function () {
        if (this.checked) {
          if (this.value === "per_scoped_label") {
            targetDiv.classList.toggle('sf-hidden');
          } else {
            targetDiv.classList.add('sf-hidden');
          }
        }
      });
    });
  }

  if (document.querySelector('#time_zone')) {
    const time_zone_select = document.getElementById('time_zone');

    if (!time_zone_select.value) {
      console.log(time_zone_select.value)
      console.log(jstz.determine().name())
      time_zone_select.value = jstz.determine().name();
    }
  }
});
